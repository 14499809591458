
.side-logo {
padding:10px 10px;
border-bottom: 1px solid rgba(255, 255, 255, 0.04);
margin-bottom: 20px;
}

.side-logo h2{
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0;
}

.side-bar ul{
margin:0;
padding:0;
}

.side-bar ul li{
line-height: 30px;
margin-bottom: 5px;
padding:0 20px;
}

.side-bar ul li a{
	color: #7B7A84;
	font-size: 14px;
	font-weight: 600;
	text-decoration: none;
	padding: 5px 10px;
	width: 100%;
display: block;
}

.side-bar ul li i{
float: right;
padding-top:7px;
font-size: 16px;
}

.side-bar ul li a:hover{
text-decoration:none;
color:#fff;
}

.side-bar ul li .active{
	color: #fff;
	background-image: linear-gradient(to right, #F47A32, #CA68B1);
	border-radius: 11px;
	width: 100%;
	display: block;
}

.side-bar ul li img{
height: auto;
width:20px;
margin-right: 7px;
}

.side-bar ul li a span{
background: linear-gradient(101.25deg, #2B3551 0%, #1C253D 100%);
border-radius: 3px;
color: #4667BE;
font-size: 12px;
font-weight: bold;
width: 44px;
height: 21px;
display: inline-block;
float: right;
padding: 0px 0px;
text-align: center;
line-height: 21px;
margin-right: 25px;
margin-top: 5px;
}


 .side-bar {
width:17%;
min-height:100vh;  
height:auto; 
background-color: #1c1b25;
position:relative;
}

.position-absolute
{
	 position:absolute;
}
 
 .bottom-0
 {
	bottom:0;
 }
 
 .update-container
 {
 padding-left: 18px;
padding-right: 18px;
padding-bottom: 18px;
 }
 
 .progress_lbl {
	 display: flex;
	 margin-top: 17px;
 }
  
 .progress_lbl label{
	font-size: 15px;
	width: 50%;
	color: #fff;
	font-weight:400;
}

.progress_lbl .hx{
	text-align: right;
	font-weight: 600;
}

.guide_btns{
	display: flex;
  padding-left: 4%;
  padding-top: 13px;
}

.guide_btns button{
	border-radius: 6px;
	background: #2B2A34;
	color: #fff;
	border: 1px solid #2B2A34;
	font-size: 14px;
	font-weight: 600;
	padding: 3px 6px;
	margin-right: 7px;
}

.guide_btns button a{
	color: #fff;
	text-decoration: none;
}

.upgrade 
{
	background: linear-gradient(to right, #F47A32, #CA68B1);
	padding: 15px;
	border-radius: 10px;
	width: 100%;
	margin: auto;
}

.up_pos
{
	position: absolute;
	bottom: 10px;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.upgrade p{
	margin-bottom: 0;
}

.upgrade h5
{
color: #fff;
  font-size: 14px;
  font-weight:600;
  margin-bottom: 0;
}

.version 
{
background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  position: relative;
}

.version h4{
color: #16151F;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.version img{
position: absolute;
  right: 10px;
  top: 13px;
}

.version p{
color: #1c1b2599;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}
