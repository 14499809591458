.right-side {
	padding: 0;
  width: 83%;
  height: 100vh;
  background-color: #16151F;
}
  
.padding-16-10-18 {
padding: 16px 10px 15px 18px;
}
 
 .slider-content-wraper{
	position: relative; 
 }
 
 .renew-right-bar {
	background-color:#b6cc43;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 0;
	min-height: 185px;
	position: relative;
	padding: 55px 0px;
  }
 
  .margin-top-40 {
	margin-top: 40px;
  }

  .margin-bommtom-10 {
	margin-bottom: 10px;
  }

  .margin-bottom-15 {
	margin-bottom: 15px;
  }

  .backgroun-graident1 {
	background: linear-gradient(0deg, #1C1C1C, #1C1C1C), #141414;
	margin-bottom: 1em;
	padding: 5px 0px;
	}
	
  .border-n1 {
	border: 1px solid #222222;
  }

  .padding-20 {
	padding: 20px;
  }

  .width-50 {
	width: 50%;
  }

  .green-light-color {
	color: #98DEB8;
  }

  .dark-bg2 {
	background-image: url("../assets/images/wallet.png");
	min-height: 130px;
	padding: 45px 0px;
  }

  .dark-background-right {
	background-color: #2b2b2b;
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 0;
	min-height: auto;
	padding: 19px 0px;
	width: 89%;
	margin: auto;
	  margin-right: auto;
	  margin-bottom: auto;
	  margin-left: auto;
	margin-right: auto;
	margin-left: auto;
	margin-right: auto;
	margin-left: auto;
	margin-left: 5%;
	margin-right: 5%;
	border-radius: 5px;
	margin-bottom: 1em;
  }
  
  .task-btns span {
	padding: 5px 7px !important;
  }

  .plus-icon {
	margin-right: 7px;
	border-radius: 5px;
  }

  .dark-bg2 {
	background-image: url("../assets/images/wallet.png");
	min-height: 130px;
	padding: 45px 0px;
  }

  .backgroun-graident1 {
	background: linear-gradient(0deg, #1C1C1C, #1C1C1C), #141414;
	margin-bottom: 1em;
  }
 
  .boss-logo {
	position: absolute;
left: 10%;
bottom: -14%;
border: 1px solid #fff;
border-radius: 50px;
height: 60px;
width: 60px;
text-align: center;
padding: 11px 0px;
  }

  .boss-logo img{
	  width: 45px;
  }

.profile-wraper{
margin-top: 15px;
background-image: url("../assets/images/profile.png");
width: 100%;
background-position: 0 0;
background-repeat: no-repeat;
background-size:100% 100%;
min-height: 230px;
}
 
.profile-1 h3{
margin-bottom: 1px;
}

.profile-1 p{
color: #fff9;
margin-bottom: 2px;
}

.profile-left{
width: 85%;
}

.profile-right{
float: right;
text-align: right;
width: 15%;
padding-top: 5px;
}

.next-btn{
margin-top: 10px;
margin-bottom: 10px;
}

.leftnext-btn{
width: 48%;
margin-right: 4%;
}

.rightnxt-btn {
width: 48%;
}

.select-div{
background: #121215;
border: 2px solid #19181D;
width: 100%;
border-radius: 5px;
position: relative;
}

.select-div select{
color: #86878A;
background-image: url("../assets/images/select.png");
background-size: auto auto;
background-position: 92% 50%;
background-repeat: no-repeat;
border: none;
background-color:transparent;
appearance: none;
width: 100%;
padding: 10px;
font-size: 13px;
font-weight: 600;
height: 45px;
}

.select-div::before
{
content: "";
position: absolute;
height: 20px;
width: 2px;
background-color: #19181D;
display: inline-block;
right: 22%;
z-index: 9999;
top: 13px;
}

.search-transaction-input input {
color: #86878A;
background-image: url("../assets/images/search.png");
background-size: auto auto;
background-position: 0% 50%;
background-repeat: no-repeat;
border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
border-bottom-color: currentcolor;
border-bottom-style: none;
border-bottom-width: medium;
background-color: transparent;
appearance: none;
width: 100%;
font-size: 13px;
font-weight: 500;
height: 45px;
border-bottom: 1px solid #19181D;
padding: 1px 20px;
}

.search-transaction-input input:focus{
outline: none !important;
}

::placeholder {
color:#86878A !important;
}
 
.search-transaction-input{
margin-top: 10px;
}

.items-details-right{
  margin-top:15px;
}

.item-details-wraper {
border-bottom: 1px solid #19181D;
margin-bottom:10px;
}

.item-details-left h4
{
margin-bottom: 1px;
}

.item-details-right
{
width: 25%;
}

.item-details-left
{
width: 77%;
}

.item-details-right h5{
background: linear-gradient(101.25deg, #262f4b 0%, #262f4b 100%);
color: #4667BE;
padding: 10px 0px;
}
 
.carousel-inner > .item > a > img, .carousel-inner > .item > img{
width: 100%;
min-height: 245px;
}

.slider-image-wraper {
position:absolute;
width: 100%;
}

.slider-content--wraper {
position: relative
}

.slider-text-left{
	padding: 20px;
	width: 80%;
}

.slider-text-left h4{
margin-bottom: 1px;
}

.carousel-inner {
	min-height: 200px;
	background: linear-gradient(to right, #F47A32, #CA68B1);
	border-radius: 5px;
}

.carousel-item{
	background-image: url("../assets/slider_lines.svg");
	background-size:74% 100%;
	background-repeat: no-repeat;
	background-position: 50px 0;
}

.carousel-item p span{ 
	background-color: #fff;
	color: #161616;
	font-size: 13px;
	font-weight: 500;
	border-radius: 3px;
	padding: 2px 6px;
}

.carousel-item h4 {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	margin-top: 14px;
}

.carousel-item p{
	color: rgba(255, 255, 255, 0.72);
}

.slider-image-wraper img{
min-height: 245px;
width: 100%;
border-radius: 5px;
}

.slider-text-left p{
	color: #ffffffb8;
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 14px;
  }

.slider-text-left button{
background: #7893da;
border: 1px solid #7893da;
padding: 13px 70px 13px 16px;
}

.slider-text-left h5{
margin-top:20px;
}

.slider-text-left img{
margin-right: 5px;
margin-left: 5px;
}

.slider-right-shoe img{
  position: absolute;
  top: 80px;
  right: -10px;
}

.bg-green-light{
background-color:#6ebd76 !important;
border: none !important;
}
.right-side-tabs ul li button{
	margin-right: 14px;
}
.carousel-indicators {
top: 23px;
right: 15px !important;
bottom: 10px !important;
left: auto !important;
width: auto !important;
margin-left: 0 !important;
}

.carousel-indicators li{
background-color:#ffffff4d;
border: none;
}

.carousel-indicators [data-bs-target]{
	border-radius: 50%;
	width: 10px !important;
	height:10px !important;
}

.carousel-indicators{
	margin-right:0 !important;
}

.right-bar{
	width: 30%;
	padding-left: 15px;
	max-height: 90vh;
  overflow: auto;
}

.padding22{
	padding: 1px 20px;
}

.connection_right {
	padding: 18px 10px 0px 0px;
}

.connection_right h5{
	color: #fff;
	font-size: 15px;
	font-weight: 600;
}

.check_inp input{
	background-image: url('../assets/search_white.png');
	background-size: 13px auto;
  background-repeat: no-repeat;
  background-color: #1C1B25;
  border: 1px solid #272630;
  background-position: 4px 10px;
  border-radius: 7px;
  width: 100%;
  font-size: 13px;
  padding:1px 13px 0px 27px;
  height: 35px;
  color: #fff;
}

.second_section_graph 
{
padding: 12px;
}

.checkout_graph {
	border-radius: 10px 10px 0px 0px;
	border: 1px solid #272630;
	background: #1C1B25; 
	padding: 15px 15px;
	color: #fff;
	min-height: 170px;
}

.checkout_graph .btn, .queue_passedgraph .btn{
	border-color: #2B2A34;
	border-radius: 8px;
	background-color: #2B2A34;
}

.checkout_graph .text-secondary , .queue_passedgraph .text-secondary{
	color: #7B7A84 !important;
	font-size: 14px;
	font-weight: 500;
}

 .checkout_graph .text-success {
	color: #12B76A !important;
}

  .bor1{
	border:1px solid #272630 !important;
  }

  .recharts-wrapper{
	font-size: 13px;
	color: #666575;
  }
  
  .mbg
  {
  margin-bottom: 20px;
  }

  .m-0 {
	margin-bottom:0 !important;
  }

  .padding-1{
	padding: 1px 15px !important;
  }

.graph_h2 {
	font-weight: 600;
  color: #fff;
  font-size: 14px;
  margin-top:20px;
  margin-bottom: 15px;
}

.graph_h1{
	font-weight: 700;
	color: #fff;
	font-size: 20px;
}

.queue_passedgraph{
	border-radius: 0px 0px 10px 10px;
	border: 1px solid #272630;
	background: #16151F; 
	padding: 12px 15px;
	color: #fff;
	min-height: 170px;
}

.monthly_analytic{
	border-radius: 10px;
  border: 1px solid #272630;
  background: #16151F;
  padding: 12px 15px;
  color: #fff;
  margin-top: 1em;
  min-height: 200px;
}


.filters button{
background-color: #1C1B25;
  border: 1px solid #272630;
  color: #fff;
  font-size: 12px;
  border-radius: 7px;
	height: 35px;
	width: 100%;
}

.filters button img{
	width:15px;
}

.filters {
	width:25%;
}

.check_inp {
	width:100%;
	margin-right: 2%;
}

.checkout_inputs {
	display: flex;
  margin-bottom: 10px;
}

.checkout_boxes {
	border-radius: 10px;
	border: 1px solid #272630;
	background: #1C1B25; 
	margin-bottom: 12px;
	padding: 8px;
}

.check_product{
	background-color: #fff;
  border-radius: 6px;
  height: 35px;
  width: 36px;
}

.check_product img{
	width: 100%;
	/* height: 100%; */

}

.check_right h5{
color: #fff;
max-height: 16px;
max-width: 280px;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
}

.check_right ul li{
padding-right: 7px;
  color: #7B7A84;
  font-size: 12px;
  font-weight: 600;
  border-right: 2px solid #222126;
  line-height: 13px;
  margin-top: 4px;
	margin-right: 6px;
	max-width: 200px;
	       text-overflow: ellipsis;
}

.check_rightt ul li:last-child {
		border-right: none;
	}

	.check_right h5{
		color: #fff;
		font-size:13px;
		margin-bottom: 0;
	}

	.check_right {
		padding-left: 7px;
	}

	.checkList li{
		list-style: none;
  background-color: #2B2A34;
  border-radius: 5px;
  margin-right: 5px;
  color: #fff;
  font-size: 13px;
  padding: 2px 8px;
  font-weight: 600;
	}

	.checkList {
		display: flex;
		margin-top: 10px;
		margin-bottom: 3px;
	}

	.checkList i{
		font-size: 7px !important;
		color: #45CC8B;
	}
	
	.checkList .active{
		background-image:linear-gradient(to right, #F47A32, #CA68B1)
	}

	.slider_section {
		width: 32%;
		border-right: 1px solid #1F1E28;
		padding-right: 15px;
		height: 90vh;
  		overflow: hidden;
	}

	.second_section_graph {
		width:36%;
	}

	.quick_links {
		margin-top:10px;
		background-color: #1C1B25;
		border: 1px solid #272630;
		border-radius: 7px;
		padding: 13px;
		padding-right: 0;
	}

	.links {
		margin-bottom: 5px;
		display: flex;
	}

	.links a{
		font-size: 14px;
		margin-right: 5px;
		color: #7B7A84;
		text-decoration: none;
		padding: 2px 8px;
		border-radius: 5px;
		font-weight: 500;
	}

	.links .active{
		background: #2B2A34; 
		color:#fff;
	}

.link_boxs {
	margin-top:10px;
	display: flex;
	flex-wrap: wrap;
}

.lbx1 {
	margin-right: 18px;
	text-align: center;
}
 
.lbx1 p{
	color: #7B7A84;
	font-size: 13px;
  padding-top: 5px;
  margin-bottom: 0;
}

.latest_qu{
	margin-top: 10px;
  margin-bottom: 0px;
}

.latest_qu .checkList li {
	font-weight: 500;
	font-size: 12px;
}

.latest_qu .checkList li span{
	color: #7B7A84;
}

.latest_qu  .check_product {
	padding: 3px;
	background-color: #2B2A34;
  border-radius: 10px;
}

.latest_qu  .check_product img
{
max-width: 28px;
}

.latest_qu .connection_right{
	padding: 0;
}

.c1{
	color:#ea7536 !important;
}

.c2{
	color: #12B76A !important; 
}

.c3{
	color: #7177EC !important; 
}

.c4{
	color: #FF7272 !important; 
}

.clearall {
	background: #ff5e5e33;
	color: #FF5E5E;
	border: 1px solid #ff5e5e33;
	width: 100%;
	margin-top: 8px;
	height: 34px;
	border-radius: 6px;
	font-size: 13px;
}

.clearall a{
	color: #FF5E5E;
	text-decoration: none; 
}
 

.auto_refrece {
	display: flex;
}

.auto_refreces {
	margin-right: 15px;
	background-color: #23222C;
	border: 1px solid #2C2B35;
	width: 100%;
	display: flex;
	border-radius: 6px;
	height: 35px;
	margin-top: 8px;
	padding: 2px;
}

.auto_img
{
 background-color: #31303A;
  border-radius: 9px;
  padding: 0px 2px;
}

.auto_text{
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	padding-left: 10px;
	padding-top: 5px;
}

.log_his {
	padding: 1px;
}

.log_his p{
	color: #E8A7B5;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
}

.log_his .log_success{
	color: #12B76A; 
}
.log_his .log_warning{
	color: #d7cc34; 
}
.log_his .log_error{
	color: #b71212; 
}
.log_his .log_inqueue{
	color: #1275b7; 
}

.switch {
	display: inline-block;
	position: relative;
	width: 50px;
	height: 25px;
	border-radius: 20px;
	background: #282731;
	transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
	vertical-align: middle;
	cursor: pointer;
}
.switch::before {
	content: '';
	position: absolute;
	top: 2px;
	left: 4px;
	width: 20px;
	height: 20px;
	background: #FF7272;
	border-radius: 50%;
	transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
    background: #282731;
}
input:checked + .switch::before {
	left: 25px;
	background: #12B76A;
	top: 2px;
}
input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}

.switch {
	margin-bottom: 0;
	margin-top: 0;
}
