.left-menu-text-with-image {
    display: flex;
    align-items: center;
}

.left-menu-text {
    flex: 1;
    padding-right: 20px;
    /* Ajustez la marge à votre convenance */
}

.left-menu-image-container {
    height: 100%;
}

.left-menu-image {
    height: 100%;
    width: 50px;
    border-radius: 50%;
    /* L'image s'ajustera automatiquement à la hauteur de la div parente */
}