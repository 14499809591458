nav{
border-bottom:1px solid #ffffff0a;
padding:17.5px 0;
padding-right: 10px;
}

nav .nav-left {
width:50%;
padding-left: 20px;
}

nav .nav-left i{
    font-size: 14px;
    padding-right: 3px;
    background-color: transparent;
}

.nav-left h3{
font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.nav-left h3 span{
    color: #ca6796;
}

nav .nav-left  ul li{
    padding-right: 7px;
    color: #7B7A84;
    font-size: 13px;
    font-weight: 600;
    border-right: 2px solid #222126;
    line-height: 13px;
    margin-top: 4px;
    margin-right: 8px;
}

nav .nav-left  ul li:last-child {
border-right:none;
}

nav .nav-left  ul li img{
margin-right: 8px;
}

nav .nav-left ul li span{
color:#fff;
}

.nav-right
{
width:50%;
}

.nav-right img{
margin-right:4px;
cursor: pointer;
}

.adjust-header{
    margin-bottom: 0;
    padding-left: 0;
}

.adjust-header .success{
    color: #45CC8B;
    font-size: 8px;
    padding-left: 2px;
}

.right_list {
    float:right;
    margin:0;
    padding: 0;
}

.right_list li{
    margin-right: 10px;
    background-color: #1c1b25;
    color: #7B7A84;
    border:1px solid #272630;
    border-radius: 5px;
    padding:6px 10px 5px 13px;
    font-size: 13px;
    font-weight: 600;
}

.right_list li span{
    color: #fff;
}

.right_list li a{
    background-color: #2B2A34;
    color: #fff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 6px;
    margin-left: 5px;
    font-size: 12px;
}
