body {
  font-family: 'Red Hat Display', sans-serif !important;
  margin: 0;
  padding: 0;
  background-color: #0d0c10 !important;
  overflow: hidden;
}

/*common styles*/
.bg-blur{background-color: #cfc6c64d !important;}
.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.white {
  color: #fff;
}

.gray-color {
  color: #86878a !important;
}

.light-blue-color {
  color: #b6cc43;
}

.dark-blue-color {
  color: #3f5aa2;
}

.green-color {
  color: #b6cc43;
}

.green-color-background {
  background-color: #62c06b !important;
}

.pink-color {
  color: #ff7272 !important;
}

.pink-color-background {
  background-color: #ff7272;
}

.brown-background {
  background-color: #be8746;
}

.brown-color {
  color: #be8746;
}

.dark-blue-background {
  background-color: #26304c;
}

.light-blue-color {
  color: #b6cc43;
}

.pink-dark-background {
  background: #592f31;
}

.dark-green-background {
  background: #2a462f;
}

.brown-dark-background {
  background: #463524;
}

.gray-color-background {
  background-color: #363639;
}

.dark-blue-background {
  background: linear-gradient(101.25deg, #262f4b 0%, #262f4b 100%);
}

.setting-input {
  background-color: #21202A;
  line-height: 15px;
  padding: 0px 15px;
  height: 40px;
  width: 100%;
  border: 1px solid #27252d;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
}
.setting-input-locked {
  background-color: #080707;
  line-height: 15px;
  padding: 0px 15px;
  height: 40px;
  width: 100%;
  border: 1px solid #27252d;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
}

.modal-content .setting-input 
{
  border: 1px solid #2C2B35 !important;
  margin-top: 0px !important;
  height: 35px;
}

.sava-btn button{
  background: var(--gradient1, linear-gradient(90deg, #EC7631 0%, #C364AB 100%));
  text-transform: capitalize;
  border: none;
  line-height: 25px;
  padding: 7px 15px;
  width: auto;
  font-size: 13px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
}

.mag_1{
margin-top: 38px;
}

.sava-btn {
  margin-right: 2%;
}

.test_api button{
  border-radius: 8px;
  border: 1px solid #2E2D37;
  background: #23222C;
  color: #62C06B !important;
  text-align: left;
  padding-left: 10px;
}

.test_api button img
{
  float: right;
  margin-top: 6px;
  margin-right: 0;
  margin-left: 20px;
}

.test_api button {
  margin-top: 15px;
}

.margin-left2 {
  margin-left: 2%;
}

.background-4 {
  background-color: #1e1f25;
}

.background-1 {
  background-color: #0d0c10;
}

.settings-section {
  padding-right: 15px;
  margin-right: 15px;
  padding-top: 20px;
  padding-left:15px;
  width: 100%;
}

.setting_container{
  margin:0;
}

.background-2 {
  background-color: #121215;
}

.b1 {
  background-color: #1c1c1c;
}
.border-radius-5 {
  border-radius: 5px;
}

.border-2 {
  border: 1px solid #272630  !important;
}

.border_2 {
  border: 1px solid #272630  !important;
}

.border-active {
  border: 2px solid #3c3b42 !important;
}

.item2-wrape {
  background-color: #1c1c1c;
}

.border-bottom-2 {
  border-bottom: 1px solid #27252d;
}

.border-top-2 {
  border-top: 2px solid #19181d !important;
}

.border-right-2 {
  border-right: 1px solid #27252d;
}

.border-right-1 {
  border-right: 1px solid #27252d;
}

.padding-left-right-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-20 {
  padding: 20px;
}

.padding-5 {
  padding: 5px;
}

.padding-6 {
  padding: 6px;
}

.padding-7 {
  padding: 7px;
}

.padding-8 {
  padding: 8px;
}

.padding-9 {
  padding: 9px;
}

.padding-10 {
  padding: 10px;
}

.padding-11 {
  padding: 11px;
}

.padding-12 {
  padding: 12px;
}

.padding-13 {
  padding: 13px;
}

.padding-14 {
  padding: 14px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-15 {
  padding: 15px;
}

.padding-10-30 {
  padding: 10px 30px 10px 30px;
}
.padding-15-20-6 {
  padding: 15px 20px 6px 20px;
}
.padding-15-30 {
  padding: 15px 15px 30px 15px;
}

.padding-10-1-15 {
  padding: 10px 0px 1px 15px;
}
.padding-right-15 {
  padding-right: 15px;
}
.padding-10-0 {
  padding: 10px 10px 0px 10px;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-right-12 {
  margin-right: 12px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}
.margin-top-5 {
  margin-top: 5px;
}

.margin-top-28 {
  margin-top: 28px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margiin-top-20 {
  margin-top: 20px;
}

.margin-auto {
  margin: auto;
}

.right {
  float: right !important;
}

.width-auto {
  width: auto;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.margin-left-12 {
  margin-left: 12px;
}

.width-25 {
  width: 25.7% !important;
}

.width-75 {
  width:75%;
}

.width-90 {
  width: 90%;
}
.width-20 {
  width: 20%;
}

.width-97 {
  width: 97.5%;
}
.width-50 {
  width: 50%;
}

.width-95 {
  width: 95%;
}

.width-400 {
  width: 400px;
}
.width-40 {
  width: 40% !important;
}
.width-20 {
  width: 20% !important;
}

.width-14 {
  width: 14% !important;
}

.width-175 {
  width: 175px;
}
.width-58 {
  width: 58.6% !important;
}

.col-2 {
  position: relative;
  float: left;
  width: 13.7% !important;
  margin-right: 7px;
}

.task-btns h5 i {
  float: right;
  padding-right: 3px;
  padding-top: 2px;
}

.col-1 {
  position: relative;
  float: left;
  width: 40% !important;
  margin-right: 7px;
}

.col-1 h2 {
  padding-top: 9px;
}

.input-div textarea {
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #86878a;
  font-weight: 500;
  padding: 0px 0px 0px 15px;
}

.select-div select:focus,
.modal-header .btn-close:focus,
.input-div textarea:focus,
input[type='text']:focus {
  outline: none !important;
  box-shadow: none !important;
}

/*common styles end*/

.main-container {
  display: flex;
}

ul {
  padding: 0;
  margin: 0;
  padding-left: 0 !important;
}

ul li {
  list-style: none;
}

.flex {
  display: flex;
}

.container {
  width: 100%;
}

/* used in left bar and right bar start*/

.update-header {
  padding: 15px 19px;
}

.update-section {
  padding: 20px;
}

.left-icon-update {
  padding: 8px;
  width: 48px;
  height: 48px;
}

.left-icon-update img {
  width: auto;
}

.right-update-txt {
  padding: 2px 0px 2px 12px;
}

.right-update-txt h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.right-update-txt p {
  padding-top: 2px;
}

.beta-item {
  margin-top: 5px;
  margin-bottom: 10px;
}

.download-button {
  margin-top: 20px;
}

.beta-item img {
  margin-right: 7px;
}

/* used in left bar and right bar end*/

table {
  padding: 0;
  margin: 0;
  width: 100%;
  border-collapse: separate;
}

table tr th {
  color: #fff;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
}

table tr {
  background:transparent;
  border: 1px solid #272630;
}

table tr td {
  padding: 5px 5px 5px 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
 
table h4 {
  color: #fff;
  font-size: 13px;
  margin-bottom: 1px;
  font-weight: 600;
  margin-top: 10px;
}

.img-1 {
  width: 40px;
  margin-top: 20px;
}

.imp {
  margin-top: 32px;
}

.sec {
  width: 60px;
}

.cap {
  width: 35px;
  margin-top: 30px;
}

font-size-23 {
  font-size: 23px;
}

.width-70 {
  width: 70%;
}

.width-30 {
  width: 30%;
}

table p {
  color: #86878a;
  font-size: 12px;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

* {
  scrollbar-width: initial;
  scrollbar-color: #C364AB  #16151f ;
}
 

.button {
  background: linear-gradient(101.25deg, #b3d20f 0%, #b2bb82 100%);
  text-transform: capitalize;
  border: none;
  line-height: 25px;
  padding: 10px;
  width: 100%;
  font-size: 13px;
}

.button-dark {
  color: #86878a;
  background: #121215;
  border: 2px solid #19181d;
  border-radius: 5px;
  padding: 5px 0px;
  width: 100%;
  font-size: 13px;
  height: 45px;
  font-weight: 600;
}

/* Adonis Bot Bordered Table */

.ab-table-border-2ed td {
  border-top: 1px solid #272630;
  border-bottom: 1px solid #272630;
  background-color: #1C1B25;
}

.ab-table-border-2ed td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #272630;
}

table tr th:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #272630;
}

table tr th:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #272630;
}


.ab-table-border-2ed td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #272630;
}

/* Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x) */

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* ===== Scrollbar CSS ===== /
/ Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: linear-gradient(101.25deg, #6d8de1 0%, #3b5eb8 100%) #19181d;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: #19181d;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(101.25deg, #6d8de1 0%, #3b5eb8 100%);
  border-radius: 10px;
}

.right-sidebar {
  width: 25%;
  padding-right: 20px;
}

/* middle section*/

.middle-section {
  width: 75%;
  padding-right: 15px;
  margin-right: 15px;
  padding-top: 20px;
}

.item1 .user-img img {
  width: 40px;
}

.item1 h2 {
  margin-bottom: 3px;
}

.right-side-tabs ul li button {
  color: #a3a4a8;
  margin-right: 3px;
  padding: 8px 22px;
}

.right-side-tabs ul li button:hover {
  background: #b6cc43;
  color: #fff;
}

.right-side-tabs ul li .active-btn {
  background: #b6cc43;
  color: #fff;
  padding: 12px 22px;
  border: none;
}

/*task page styles*/
.task-btns h5 {
  margin-bottom: 0;
}

.search-btns-task input {
  width: 90%;
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: #86878a;
  font-weight: 500;
  padding: 0px 1px 0px 19px;
}

.search-btns-task img {
  float: left;
  position: absolute;
  margin-top: 5px;
}

.search-btns-task {
  padding: 6px 0px 10px 10px;
}

.task-btns {
  padding: 10px 10px 13px 15px;
}

.active-p {
  background-color: #1b1b1e;
}

.right-side-tabs ul li {
  margin-right: 0;
}

.task-groups-right {
  margin-top: 22px;
  max-height: 88vh;
  overflow: auto;
}

.task-create-plus {
  height: 35px;
  width: 35px;
  padding: 2px;
  cursor: pointer;
}

.task-create-plus img {
  opacity: 0.5;
}
.left-taskg {
  width: 85%;
}

.group-task h5 {
  margin-bottom: 4px;
  color: #fff9;
}

.taks-id {
  height: 30px;
  width: 30px;
  padding: 4px 0px;
  margin-top: 10px;
  margin-right: 5px;
}

.tasks-table td {
  border-top: none;
  border-bottom: 1px solid #27252f;
}

.tasks-table tr td {
  padding:0px 0px 4px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.tasks-table tr {
  border: none;
}

.tasks-table td:last-child {
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.up-prop {
  height: 42px;
  width: 42px;
  margin-right: 10px;
}

.form-group {
  margin-bottom: 10px !important;
}

/*14 march styles*/

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }
}

/*Modal css*/
.modal-header {
  background-color: #1C1B25;
  border: none !important;
  border-radius: 0 !important;
  padding: 10px 0px 0px 15px !important;
}

.btn-close {
  background-image: none !important;
  opacity: 1 !important;
  background-size: auto auto !important;
  background-color: #32313B !important;
  width:auto !important;
  height:auto !important;
  font-size: 12px;
  padding: 2px 7px !important;
  font-weight: 500;
  margin: 0px  !important;
   margin-right: 0px;
   color: #fff;
  margin-right: 5px  !important;
}

.btn-close:hover{
  color: #fff !important;
}

.modal-content {
  background-color: #1C1B25 !important;
  border-radius: 6px !important;
  padding: 3px !important;
}

.recharts-surface
{
  height:100% !important;
}


/* **********common Css************** */


.bg-success-light {
  background-color: #194a3a !important;
}
.bg-primary-light {
  background-color: #392862 !important;
}

/* *************main_section*********** */




.myspan
{
  font-size: 1.2rem;
}
.mypfont
{

  font-size: 0.8rem !important;
}
.progress {
  height: 10px !important;
  margin-bottom: 11px;
  overflow: hidden;
  background-color: #19181d;
  border-radius: 10px;
  box-shadow: none;
  position: relative;
  top: -4px;
}

.recharts-responsive-container{
  height: 100px !important;
}
.modal-footer {
  padding: 0px 0px 0px 20px;
  border-top: none;
  align-items: flex-start;
  justify-content: flex-start;
}

.modal-dialog button {
  border: none;
  color: #fff;
}

.modal .sava-btn {
  padding: 10px 28px;
  width: auto;
}

.modal-dialog .pink-darkbg {
  color: #ff7272;
}

.modal-dialog {
  max-width: 600px !important;
  margin: 30vh auto;
}

.option {
  color: #86878a;
  background: #121215;
  border-radius: 5px;
  width: 100%;
}

.modal-dialog .select-div {
  width: 95%;
}

.modal-dialog .select-div select {
  padding: 0px 10px;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  margin-top: -8px;
}

.modal-dialog label {
  padding-left: 0;
  padding-bottom:3px;
}

.popup-left-selects {
  margin-right: 2%;
}

.modal-dialog .select-div::before {
  top: 50%;
}

.modal-margin {
  margin: 10vh auto !important;
}

.input-div {
  position: relative;
  padding-bottom: 8px;
}

.input-div input {
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #86878a;
  font-weight: 500;
  padding: 0px 0px 0px 15px;
}

.modal-dialog .active-btn {
  background: linear-gradient(101.25deg, #6d8de1 0%, #3b5eb8 100%);
  color: #fff !important;
  padding: 8px 15px 8px 15px;
  border: none;
  border-radius: 7px;
  width: auto;
  text-align: left !important;
}

.pop_text
{
background-color: #21202A;
  border-radius: 4px;
  border: 1px solid #2C2B35;
  padding: 3px 7px;
 margin-right: 2%;
}

.pop_text p{
  color: #7B7A84;
  font-size: 12px;
  margin-bottom: 3px;
}

.profiles-sections h3 {
  width: 90%;
}
.line-border {
  border: 1px solid #19181d;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.proxy table tr td {
  padding: 0px 0px 9px 0px;
}

.margin-top-320 {
  margin-top: 320px;
}

.margin-top-100 {
  margin-top: 100px;
}

.aaa {
  color: #aaa;
}

.pointer {
  cursor: pointer;
}

.dark-green-hover:hover {
  background-color: #223526 !important;
  border: 2px solid #223526 !important;
}

.dark-green-hover {
  background-color: #223526 !important;
  border: 2px solid #223526 !important;
}

.green-bg {
  background-color: #b6cc43;
  border: 2px solid #b6cc43 !important;
}

.dark-gray-hover {
  background-color: #1e1f25;
  border: 2px solid #1e1f25;
}

.dark-gray-hover:hover {
  background-color: #1b1b1e !important;
  border: 2px solid #1b1b1e !important;
}

.delete-bg {
  background-color: #ff7272 !important;
  border: 2px solid #ff7272 !important;
}

.dark-brown-hover:hover {
  background-color: #34291f !important;
  border: 2px solid #34291f !important;
}

.delete-image-hover {
  display: none;
}

.image-hover-none:hover .profile-image-hover {
  display: none;
}

.image-hover-none:hover .delete-image-hover {
  display: block;
}

.image-hover-none {
  height: 40px;
  width: 40px;
  padding: 10px 2px 0px 11px;
}
 
.delete-image-hover {
  margin-top: 0 !important;
}

.width-19-5 {
  width: 20%;
}

.table-scrolling {
  max-height: 100%;
  overflow-y: visible;
  margin-bottom: 0em;
  padding-right: 10px;
  overflow-x: hidden;
  margin-top: 12px;
}

.table-scrolling thead th {
  background: #16151F;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #272630;
  border-top: 1px solid #272630;
}

.ab-table-border-2ed {
  border-collapse: separate;
  border-spacing: 0;
}

.padding-right-10 {
  padding-right: 10px;
}

.width-106 {
  width: 106%;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-18 {
  padding-right: 18px;
}

.item1 .user-img {
  width: 10%;
  text-align: right;
  padding-right: 14px;
}

.middle-section-container {
  padding: 0;
}

textarea {
  resize: none;
}

.modal-body {
  background-color:#1C1B25;
  padding: 1px 15px;
  cursor: default;
}

.middle-section {
  min-height: 90vh;
}

.item2 button {
  width: 100%;
  text-align: left;
}

/* 4-4 styles*/

.padding-right-15 {
  padding-right: 15px;
}

.padding-left-12 {
  padding-left: 12px;
}

.padding-right-16 {
  padding-right: 16px;
}

.padding-right-14 {
  padding-right: 14px;
}

.width-95 {
  width: 95%;
}

.padding-left-11 {
  padding-left: 11px;
}

.padding-right-1 {
  padding-right: 1px;
}
.width-174 {
  width: 174px;
}

.margin-right-3 {
  margin-right: 3px !important;
}

.right-click-action {
  min-width: 150px;
  left: 10px;
  top: 6px;
  padding-bottom: 0;
}

.right-click-action button:hover {
  border: none !important;
  background-color: transparent !important;
}

.right-click-action li:first-child {
  border-bottom: 2px solid #19181d !important;
}

.right-click-action li:last-child {
  padding-top: 5px;
  padding-bottom: 3px;
}

.absolute {
  position: absolute;
}

.border-0 {
  border: none !important;
}

.background-transparent {
  background: transparent !important;
}

.border-radius-10 {
  border-radius: 10px;
}

table tr:hover {
  cursor: pointer;
}
 
.task-sidebar {
  width: 25% !important;
}

.item2 {
  padding-top: 15px;
}
 
.task-shoe {
  width: 45px;
}

.side-logo img {
  width: auto;
}

.idle {
  color: #b9b948;
}

.success {
  color: #09d509;
}

.decline {
  color: #ff7272;
}

.idle,
.decline,
.success {
  margin-right: 2px;
}

.padding0 {
  padding: 0;
}

.padding-top0 {
  padding-top: 0;
}
 
.active-p {
  background-color: #2d2c2a !important;
}

.table_shoe {
  width: 45px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.fa-download,
.fa-upload {
  color: #aaa;
}

.popup-right-selects {
  margin-right: 2%;
}

.fa-arrow-right {
  margin-left: 5px;
}

input:checked + .slider {
  background-color: #121215 !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(44px);
  -ms-transform: translateX(44px);
  transform: translateX(44px);
  background-color: #777474;
}

.cursor {
  cursor: pointer;
}

@media (min-width: 1900px) {
  .main-container {
    margin: auto;
    width: 100%;
  }
}
  
.sava-btn:hover,
.task-btns:hover {
  cursor: pointer;
}

.item1 .user-img{
  margin-right: 15px;
  }
  
  .left-icon-update{
  margin-right: 10px;
  }

  .task-groups-right h2{
    color: #fff;
    font-size: 15px;
    font-weight: 600;
  }

  .group-task {
    border-radius: 10px;
  border: 1px solid #272630;
  background: #1C1B25;
  margin-bottom: 12px;
  padding: 8px;
  }

  .left_profile {
    width: 45px;
    background-color: #2B2A34;
    height: 45px;
    border-radius: 12px;
    text-align: center;
    padding-top: 11px;
    margin-right: 10px;
  }

  .group-task .left_profile {
    padding-top: 7px;
  }

  .group-task .left_profile_active {
    padding-top: 7px;
  }

  .left_profile_active{
    width: 45px;
    background: rgba(255, 255, 255, 0.15); 
    height: 45px;
    border-radius: 12px;
    text-align: center;
    padding-top: 11px;
    margin-right: 10px;
  }

  .left_profile  img{
    width: auto;
    max-width: 100%;
  }

  .right_pro {
    width:auto;
  }

  .right_pro h4{
    color: #fff;
    font-size: 13px;
    background-color: #2B2A34;
    font-weight: 400;
    margin-top: 4px;
    border-radius: 6px;
    padding: 4px 7px;
    margin-bottom: 0;
  }

  .right_pro h5{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .active_group h4
  {
  background: rgba(255, 255, 255, 0.20); 
  }

  .active_group
  {
  background: url("../assets/slider_lines.svg"), linear-gradient(90deg, #EC7631 0%, #C364AB 100%), #1C1B25; 
  }

  .profile_item1 {
    padding-left:15px;
    padding-right: 15px;
    display: flex;
  }

  .user-info h2{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom:5px;
  }
 
  .user-info h5 span{
    color: #fff;
    font-size: 13px;
    border-radius: 6px;
    background: #2B2A34;
    font-weight: 400;
    padding: 3px 6px;
  }

  .profile_item1
  {
  background-color: #1C1B25;
  border: 1px solid #272630;
  border-radius: 5px;
  padding:7px;
  }

  .profile_middle {
    padding-left: 15px;
  }

  .profile_inputsearch input {
  background-image: url('../assets/search_white.png');
  background-size: 13px auto;
  background-repeat: no-repeat;
  background-color: #23222C;
  border: 1px solid #2E2D37;
  background-position: 15px 10px;
  border-radius: 8px;
  width: 100%;
  font-size: 13px;
  padding:1px 13px 0px 41px;
  height: 35px;
  color: #fff;
  font-weight: 600;
  }
 
  .create_right{
    padding-top: 8px;
    text-align: right;
    padding-left: 15px;
  }

  .active-btn {
    background: linear-gradient(90deg, #474EEB 0%, #7177EC 100%);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 7px;
      padding-left: 7px;
    width: 100%;
    text-align: left;
    padding-left: 15px;
  }

  .active-btn img{
    margin-left: 15px;
    float: right;
    margin-top: 4px;
  }

  .profile_inputsearch {
    margin-top: 8px;
  }

  .row_height {
    height: 15px;
  }

  table  .success {
    color: #4AC46C;
    background: #62C06B33;
  } 

  .pink_r {
    color: #FF7272;
    background: transparent;
  }

  .box_pro
  {
    border-radius: 5px;
    height: 30px;
    width: 30px;
    padding: 4px 0px;
    border:1px solid #2E2D37;
    margin-right: 7px;
    background-color: #23222C;
    text-align: center;
  }
 
  .box_pro  img{
    width: auto;
  }

  table tr td span{
    background-color: #2B2A34;
  padding: 2px 6px;
  border-radius: 5px;
  }

  .full_width_middle {
    min-height: 90vh;
    padding-right: 15px;
  margin-right: 15px;
  padding-top: 20px;
  width: 100%;
  }

  .select-style {
    padding: 3px;
    margin: 0;
      margin-top: 0px;
    border: 1px solid #2C2B35;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background-color: #23222C;
    display: flex;
    height: 35px;
    margin-top: 8px;
    position: relative;
  }
  .select-create-task-modal-style {
    padding: 3px;
    margin: 0;
    margin-top: 0px;
    border: 1px solid #2C2B35;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background-color: #23222C;
    display: flex;
    height: 35px;
    position: relative;
    color: white;
    font-size: small;
  }

.select-style select {
  padding: 5px 8px;
  padding-left: 8px;
width: 130%;
border: none;
box-shadow: none;
background-color: #23222C;
background-image: none;
position: relative;
padding-left:40px;
color: #fff;
font-size: 13px;
font-weight: 500;
}

.select-style select:focus {
    outline: none;
}

.select_img {
  width: 30px;
  background-color: #2B2A34;
  height: 28px;
  border-radius: 12px;
  text-align: center;
  padding-top: 0;
  margin-right: 10px;
  position: absolute;
  top: 2px;
  z-index: 9;
}

  .select_img img {
  position: relative;
  width: 80%;
}

.full_width_middle  .user-info h5 span {
  margin-right: 5px;
  font-weight: 500;
}

.full_width_middle  .user-info h5 span lable{
  color: #7177EC;
}

.waiting{
color: #EC7631;
background: linear-gradient(90deg, rgba(236, 118, 49, 0.20) 0%, rgba(195, 100, 171, 0.20) 100%); 
}

.idle{
color: #767473;
/* background: linear-gradient(90deg, rgba(236, 118, 49, 0.20) 0%, rgba(195, 100, 171, 0.20) 100%);  */
}

.success{
background: rgba(98, 192, 107, 0.20); 
color: #62C06B; 
}

.failed{
color: #FF7272; 
background: rgba(255, 114, 114, 0.20); 
}

.task_buttons button{
  background-color: #1C1B25;
  border: 1px solid #272630;
  border-radius: 8px;
  padding: 6px 10px;
  width: 160px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}

.assetsplay {
float: right;
padding-top: 6px;
}
.assetsstop {
float: right;
padding-top: 6px;
}
.assetsedit {
float: right;
padding-top: 3px;
}
.assetsdel {
float: right;
padding-top: 3px;
}

.task_buttons_delete{
float: right;
padding-top: 3px;
}

.gren{
  color: #62C06B;
}

.pink{
  color: #FF7272; 
}

.gray{
  color: #7B7A84; 
}

.task_buttons
{
  /* margin-top: 2em; */
  position: fixed;
  bottom: 2vh;
}

.test_btn button{
background-color: #23222C;
  border: 1px solid #2E2D37;
  width: 100%;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  padding: 5px 7px;
  height: 35px;
  margin-top: 7px;
}

.test_btn img{
  float: right;
  margin-top: 4px;
}
 
.form-group1 {
  display: block;
  margin-bottom:0;
}

.form-group1 input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group1 label {
  position: relative;
  cursor: pointer;
}

.form-group1 label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #1C1B25;
  border: 1px solid #272630;
  box-shadow: none;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 7px;
  border-radius: 3px;
}

.form-group1 input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #EC7631;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.pop_header span{
  color: #fff;
  background: rgba(255, 255, 255, 0.19);
  border-radius: 6px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: 400;
}

.pop_header {
  margin-bottom:10px;
}

.width-33{
  width:33%;
}

.runbtn
{
background: #23222C !important;
  border: 1px solid #2E2D37 !important;
  margin-left: 10px;
  color: #62C06B !important; 
}

.text-primary-shade{
  color: #8A54FF  !important;
}

.proxy_check{
  position: absolute;
  top: 29px;
  right: 5px;
  z-index: 9;
}

.relative{
  position: relative;
}

.check_referce {
  padding-left: 15px;
}

.checkouts_list {
  display: block;
}

.No_data {
  text-align: center;
}

.No_data h2{
  color: #fff;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 10px;
}

.No_data p{
  color: #7B7A84;
  font-size: 14px;
  font-weight: 500;
}

.No_data button{
  width:auto;
}

.checkout_nodata , .checout_error
{
padding-top: 50%;

}

.profile_nodata{
  padding-top: 20%;
  /* display: none; */
}
.task_nodata{
  padding-top: 10%;
  /* display: none; */
}

.checout_error a{
  color:#7177EC;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.checkout_nodata , .checout_error {
  display: none;
}

.profile_table11{
  display: block;
}

.task_display {
  display: block;
}

.no_proxydata {
  display: block;
}

.proxies_group_pool{
  display: block;
  max-height: 79vh;
  overflow: auto;
  height: 79vh;
}

.proxy_poolsh2 h2 {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
}

.proxyNN {
  padding-top: 50%;
  /* display:none; */
}

.checkout_container{
  display: block;
  height: 58vh;
  overflow: auto;
}

.checkout_empty 
{
padding-top: 20%;
/* display: none; */
}

.nba_left {
  margin-right:15px;
}

.nba_left img{
  width:auto;
  max-width: 200px;
}

.nba_text {
padding-right: 50px;
width: 85%;
padding-top: 10px;
}

.nba_text h3{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.nba_text p span{
  background-color: #2B2A34;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 10px;
}

#checkout_details .btn-close
{
top: 10px;
  position: absolute;
  right: 5px;
}

.success_contain {
  background: linear-gradient(to right, #F47A32, #CA68B1);
  border-radius: 7px;
  padding: 15px;
}

.success_check{
  margin-right: 15px;
  width: 69%;
}

.success_check h3 {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}

.success_check p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

.ticket_Masbtn {
  background-color: #fff;
  border-radius: 11px;
  text-align: center;
  padding: 14px 1px;
}

.ticket_Masbtn img {
  margin-top: -6px;
  width: 100%;
  max-width: 92%;
}

.ticket_mas
{
width: 28%;
}

.check_outs{
  border-radius: 8px;
  border: 1px solid #2D2C36;
  background: #21202A;
  height: 50px;
  padding: 11px;
}

.chekPop_left{
  width: 50%;
}

.chekPop_left p{
  color: #fff;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
}

.chekPop_left img{
  width: auto;
  margin-right: 10px;
  margin-top: -5px;
}

.chekPopright {
  width: 70%;
  text-align: right;
}

.chekPopright p span{
  background-color: #2B2A34;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 10px;
}

.light_blue{
  background-color: #6D72EC !important; 
}

.width_50{
  width:50%;
}

.wid20{
  width:20%;
}

.wid80{
  display: flex;
  width:57%;
}

.wid80 img{
  margin-left: 10px;
}

#checkout_details {
  max-width: auto;
  margin:auto;
  overflow: visible;
  margin-top: -2em;
}

.checkList .active:hover{
  cursor: pointer;
}


/* loader */

:root {
  --hue: 223px;
  --bg: hsl(var(--hue),90%,95%);
  --fg: hsl(var(--hue),90%,5%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (24px - 16px) * (690px - 320px) / (1280px - 320px));
}

/* .body {
  background-color: var(--bg);
  color: var(--fg);
  font: 16px/1.5 sans-serif;
  height: 100px;
  display: grid;
  place-items: center;
  transition: background-color var(--trans-dur);
} */

#loader main {
  padding: 24px 0;
}

#loader .ip {
  width: 100px;
  height: 50px;
}

#loader .ip__track {
  stroke: hsl(var(--hue),90%,90%);
  transition: stroke var(--trans-dur);
}

#loader .ip__worm1,
#loader .ip__worm2 {
  animation: worm1 2s linear infinite;
}

#loader .ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue),90%,5%);
    --fg: hsl(var(--hue),90%,95%);
  }

  #loader .ip__track {
    stroke: hsl(var(--hue),90%,15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }

  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }

  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }

  50% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -358;
  }
}

.log_head {
  display: block !important;
  text-align: right;
}

.modal-body .login-section h5 {
font-weight: 700;
font-size: 25px;
margin-bottom: 10px;
margin-top: 8px;
}

.modal-body  .login-section{
  margin-top: 0 !important;
}

.modal-body  .login-section p{
color: #7B7A84;
  font-size: 17px;
  font-weight: 500;
}

.getstardbtn
{
background: url("../assets/slider_lines.svg"), linear-gradient(90deg, #EC7631 0%, #C364AB 100%), #1C1B25;
padding: 15px;
  width: 250px;
  border-radius: 7px;
}

.getstardbtn a{
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}

.modal-body  .login-section h4{
  color: #fff;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 20px;
}

.pass_img{
  width:100%;
}

.gotosetting{
  background: linear-gradient(101.25deg, #6d8de1 0%, #3b5eb8 100%);
  color: #fff !important;
  border: none;
  border-radius: 7px;
  width: 200px !important;
  margin-top: 30px;
  padding: 15px 5px !important;
  text-align: center !important;
}

.gotosetting a{
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
}

.alert_boxes {
  background-color: #2B2A34;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  width: auto;
  margin-bottom: 10px;
}

.alert_img {
  width: 10%;
}

.alert_img img{
  width: auto;
  margin-top: 5px;
}

.alert_msg {
  width: 90%;
}

.alert_msg h2{
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1px;
}

.alert_msg p{
  color: #7B7A84;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.backImg {
  background-color: #3B3A44;
  padding: 5px 0px;
  border-radius: 9px;
  width: 44px;
  text-align: center;
}

.form-group1 span{
  position: relative;
  top: 1px;
  background-color: transparent;
  padding: 0;
}

.right_ac {
  text-align: right;
}

.right_ac .box_pro{
  float: right;
}

.table_scrolling_proxies{
  height: 76vh !important;
  max-height: 80vh !important;
  min-height: auto !important;
  overflow: auto;
}

.table_scroll_history{
  height: 79vh !important;
  max-height: 79vh !important;
  min-height: auto!important;
}

.table_scroll_task {
  height: 71vh !important;
  max-height: 71vh !important;
  min-height: 71vh!important;
}

 
