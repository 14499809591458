  .right-taskg img{
	 margin-top: -5px;
 }
 
 .up-prop1 {
  height: 35px;
  width: 35px;
  border: 1px solid #27252d;
  margin-left: 7px;
  padding: 4px;
 }
  
 .padding-8 {
  padding: 3px !important;
}

.height-15{
	height: 15px;
}

.padding-left-20{
	padding-left:20px;
}

.padding-bottom-10{
	padding-bottom:10px;
}
 
 .padding-15-20-0{
	 padding: 15px 20px 0px 20px;
 }
  
.padding-15-10-20{
  padding: 10px 10px 0px 20px;
}
 
/*checkbox styles*/
.switch {
  position: relative;
  display: inline-block;
width: 73px;
height: 31px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121215;
  -webkit-transition: .4s;
  transition: .4s;
  border: 2px solid #19181D;
}
.disabled{
  background-color: #121215;
}

.no-animation, .no-animation:before{
  -webkit-transition: 0s !important;
  transition: 0s !important;
}

.slider:before {
 position: absolute;
content: "";
height: 20px;
width: 20px;
left: 2px;
bottom: 3px;
background-color: #62C06B;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
  background-color: #121215;
}
 
input:checked + .slider:before {
  -webkit-transform: translateX(44px);
  -ms-transform: translateX(44px);
  transform: translateX(44px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.middle-section{
	padding-right: 0px;
}

/*4-6 styles*/
.padding-left-11
{
padding-left: 11px;
}

.padding-right-1{
	padding-right: 1px;
}

.search-btns-task {
    padding: 5px 0px 11px 9px;
}

.task-btns {
    padding: 10px 10px 12px 15px;
}

.profile-width{
  width: 20%;
}


.search-btns-task i {
  float: left;
  position: absolute;
  margin-top: 5px;
  color: #aaa;
}

.button-align{
  display: flex !important;
}

.button-align div{
  margin-right: 10px
}

.profiles-sections {
margin-top: 15px;
background-color: #1c1c1c;
border-radius: 4px;
border: 1px solid #27252d;
float: left;
width: 100%;
padding-bottom: 10px;
}

.profiles-sections .fa-user
{
  color: #b6cc43;
}

.profiles-sections .fa-envelope-o , .profiles-sections .fa-map-marker , .fa-birthday-cake
{
font-size: 16px;
padding-right: 5px;
}

.border-top-2 {
  border-top: 1px solid #27252d !important;
}

.profiles-sections h3{
  width: 95%;
}

.profiles-scrolling{
  margin-bottom: 1em;
}


.delete-image-hover {
  margin-top: 2px !important;
  margin-left: 5px;
}

.middle-section{
  width: 80% !important;
}
 

.background-2{
  background-color: #1C1C1C !important;
}

.card-padding{
  padding: 5px 20px;
}

.chks {
display:flex;
}

