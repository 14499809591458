 /*accounts styles */
 .padding-right-12{
	padding-right:12px;
}

.right-side-tabs {
    width: 100%;
}
 
.col-2{
	width: 21%;
}
 

table tr th{
	font-size: 15px;
}

.background-2 {
	background-color: #1c1c1c;
}

.account-side-bar{
	width: 25% !important;
}

.taks-id i{
	color: #FF7272;
}
 
.fa-eye , .fa-eye-slash
{
padding-left: 5px;
}

.table-scrolling{
	margin-bottom: 1em;
}

.gray-color i{
	color: #86878a !important;
}

.table-scrolling {
  height: 75vh;
  position: relative;
  min-height: 75vh;
  }


  .prox_dl {
	width:10%;
	text-align: right;
	padding-top: 7px;
	padding-right: 37px;
  }

  .right_pro{
	width: 74% !important;
  }
  
  .right_pro h4 span{
  color: #fff;
  font-size: 13px;
  background-color: #2B2A34;
  font-weight: 400;
  margin-top: 4px;
  border-radius: 6px;
  padding: 4px 7px;
  margin-bottom: 0;
  }

  .active_group h4 span{
  background: rgba(255, 255, 255, 0.20);
  }

  .active_group h4 {
	background-color: transparent!important;
  }

  .right_pro h4 {
	background-color: transparent !important;
	padding: 4px 0px !important;
  }

  .box_pro {
	  padding: 0 !important;
  }

   .active_group .box_pro{
	background-color: #fff;
	border: 1px solid #fff;
  }

  .addproxy {
	background: linear-gradient(90deg, #EC7631 0%, #C364AB 100%), #1C1B25;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  }

  .addproxy img{
	float: right;
  margin-top: 5px;
  margin-right: 5px;
  }

  .btn_br {
	background-color: #1F1E28;
	height: 1px;
	width: 100%;
	margin-bottom: 15px;
	margin-top: 5px;
  }
  
  table tr td .box_pro {
    padding: 3px !important;
  }

  .addProxybtn {
    background: linear-gradient(90deg, #EC7631 0%, #C364AB 100%), #1C1B25;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 9px;
      padding-left: 9px;
    padding-left: 7px;
    padding-left: 7px;
    width: auto;
    text-align: left;
    padding-left: 11px;
  }

  .addProxybtn img{
    margin-left: 10px;
  }

  .proxy_list {
    background-color: #21202A;
    border: 1px solid #2C2B35 !important;
    padding: 10px 15px;
    border-radius: 7px;
    min-height: 200px;
  }


  .proxy_list p{
    color: #7B7A84;
    font-size: 13px;
  }

  .proxy_list input{
    color: #7B7A84;
    background-color: transparent;
    border: none;
    font-size: 13px;
    padding: 0;
  }
  .proxy_list textarea{
    color: #7B7A84;
    background-color: transparent;
    border: none;
    font-size: 13px;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 200px;
  }
