.function-task{
	margin-top:35px;
margin-bottom: 3px;
}
 
.flex-boxes{
  padding:8px 15px 7px 20px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-boxes img{
	margin-right: 8px;
	margin-top: -1px;
	max-width: 14px;
}

.flex-boxes i{
padding-right: 5px;
font-size: 15px;
}

.function-task ul{
width: 100%;
}
 
.right-side-tabs ul li button {
  margin-right:10px;
}

 .col2{
	 margin-right: 7.5px;
 }

.left-5{ 
left:5px;
}

.width-49{
width: 49%;	
}

.task-image-hover {
  margin-top: -10px;
  margin-left: -10px;
}

.tasks .taks-id i{
	color: #FFF !important;
}

.delete-image-hover {
  margin-top: 2px !important;
  position: relative;
  left: -2px;
  top: 1px;
}

  .image-hover-none:hover .task-image-hover{
	  display:none;
  }
  
 /*30 march styles*/
 
 
.right-side-tabs {
  width:100%;
}

.right-side-tabs ul li{
	margin-right: 15px;
}
  
.task-grou-active
{
background-color: #1c1e1b  !important;
border:2px solid #1c1e1b  !important;
}

/*4-6- styles*/
.right-side-tabs ul li {
    margin-right: 0;
}

.table-scrolling{
	padding-right: 14px;
}

.shoes-image{
width: 46px;
height: 46px;
padding: 12px 2px 2px 2px;	
}

.product-bx {
  padding: 10px 0px 0px 10px;
  border: 2px solid #19181d !important;
  border-radius: 5px;
  background-color: #121215;
  margin-top: 15px;
}

.user-info{
padding-left: 6px !important;
}

.product-bx1{
  border: 2px solid #19181d !important;
  border-radius: 5px;
  background-color: #121215;
  min-height: 93px;
  margin-right: 10px;
  padding: 10px 8px;
  margin-top: 15px;
}

.user-img img{
  width: 45px;
}

.running .fa-circle{
  color:#68c376;
  font-size: 16px;
padding-right: 6px;
margin-top: 7px;
}

.stopped i{
  color:red;
  font-size: 16px;
padding-right: 6px;
margin-top: 7px;
}

.idle i{
  color:yellow;
  font-size: 16px;
padding-right: 6px;
margin-bottom: 2em;
}


.analytic img{
  width: 20px;
  margin-right:20px;
}

.analytic i{
  color: #04db04;
  font-size: 15px;
  margin-right: 20px;
}

.gre{
  color:#04db04;
}