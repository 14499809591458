 
.login-body {
  background-image: url('../assets/Vector.svg'), url('../assets/Vector.svg');
  background-size: 400px auto, 400px auto;
  background-repeat: no-repeat;
  background-position:0 0, 108% bottom;
  background-color: #16151f;
}

.intro_log {
  background-image: url('../assets/intro.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 15px;
  background-position: 0 0;
  margin-top: 10px;
  margin-bottom: 15px;
}

.login-slider-image {
  width: 50%;
}

.left_intro {
  width:60%;
}

.intro_log span{
  background-color: #fff;
  padding: 3px 7px;
  border-radius: 4px;
}

.left_intro h4{
  color: #161616;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.right_intro{
  text-align: right;
  width: 40%;
}

.left_intro h3{
  color: #fff;
  font-size: 23px;
  font-weight: 600;
  margin-top: 10px;
}

.login-section {
  margin-left: auto;
  margin-right: auto;
  max-width:404px;
  margin-top: 1em;
}

.margin-left-4p {
  margin-left: -4px;
}

.white-fade {
  color: #fff9;
}

.login-container .welcome-section .login-slider-image {
  margin-bottom: -3px;
}

.height-45 {
  height: 45px;
}

.progress {
  top: 11px;
  margin-left: 0;
  margin-right: 0;
}

.left-login {
  width: 100%;
  padding: 0 170px;
  padding-top: 0px;
  padding-top:15vh;
}

.login-section h5{
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.right-login {
  width: 50%;
  border-left: 1px solid #283511;
  background-color: #0e0e0e;
  padding: 0 100px;
  padding-top: 30vh;
}

.login-body {
  min-height: 100vh;
}

.login-container {
  min-height: 100vh;
}

.log_sec {
  border-radius: 10px;
  border: 1px solid #272630;
  background: #1C1B25; 
  padding: 10px;
}

.logo-login {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
}

.logo-login img {
  width: 50px;
}

.logo-login span {
  position: relative;
  top: 0;
}

.login_intro {
  margin-top: 15px;
  margin-bottom: 18px;
  width: 100%;
}

.login-right-text button{
  width:100%;
  border-radius: 8px;
border: 1px solid #383741;
background: #2B2A34; 
padding: 10px;
color: #FFF;
font-size: 13px;
font-weight:500;
line-height: normal; 
}

.login-right-text p{
  color: #7B7A84;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 8px;
}

.login-right-text img{
  width:auto;
  margin-right: 5px;
}

.login-left-text h4{
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}

.after_click {
  /* display: none; */
}

.succesful {
  /* display: none; */
}

.ok_n {
  width:auto !important;
}

.loading_img {
  width:50px !important;
  margin:auto;
}

.login_display {
  display: block;
}

.login-section h5 {
  padding-top: 0;
  margin-bottom: 30px;
  text-align: center;
}

.login-field {
  background-color: #0e0e0e;
  border-radius: 5px;
  border: 1px solid #283511;
  padding: 9px 0px 10px 13px;
  height: 45px;
}

.login-field input {
  width: 88%;
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}

.button {
  background: #b6cc43 !important;
  width: 100px;
}

.right-box {
  border: 1px solid #283511;
  border-radius: 5px;
  padding: 60px 30px;
  background-color: #1e1f25;
  width: 350px;
  margin: auto;
}

.progress-div {
  padding: 12px 0;
}

.ok-img {
  margin-top: 10px;
  margin-bottom: 10px;
}
