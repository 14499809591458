.middle-section {
  width: 100%;
}
.item1 .user-img {
  width:38px;
}

.setting-lefts {
	width:60%;
}

.padding-15-0-20 {
  padding: 15px 0px 0px 20px;
}

.setting-right  {
	width:40%;
}

.setting-right .user-info {
  margin-left: 0;
  width: 60%;
}
 
 

.line-border{
  border: none !important;
width: 100%;
margin-top: 5px;
margin-bottom: 5px;
}

.tr-th
{
padding: 15px 0px;
margin-top: -4px;
border-bottom-right-radius: 5px;
border-bottom-left-radius: 5px;
}

.margin-bottom-18 {
	margin-bottom:18px;
}

.setting-main-sc{
	padding-bottom: 15px;
}

/* .item1 .user-info {
  padding-left: 15px;
} */

.profiles-sections {
  margin-bottom: 1em;
}

 
/*checkbox styles*/
.switch {
  position: relative;
  display: inline-block;
width: 73px;
height: 31px;
margin-top: 10px;
margin-bottom: 10px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121215;
  -webkit-transition: .4s;
  transition: .4s;
  border: 2px solid #19181D;
}
.disabled{
  background-color: #121215;
}

.no-animation, .no-animation:before{
  -webkit-transition: 0s !important;
  transition: 0s !important;
}

.slider:before {
 position: absolute;
content: "";
height: 20px;
width: 20px;
left: 2px;
bottom: 3px;
background-color: #797979  !important;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
  background-color: #121215  !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(44px);
  -ms-transform: translateX(44px);
  transform: translateX(44px);
  background-color: #62C06B !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.middle-section{
	padding-right: 0px;
}

.profiles-sections label{
color: #fff;
font-size: 14px;
font-weight: 500;
}

.widthtest {
  width:100%;
  position: relative;
}

.width20 {
  width:20%;
}

.item2 button {
  width: 100%;
  text-align: center;
}

.profiles-sections select{
background-color: #0d0c10;
line-height: 15px;
padding: 0px 15px;
height: 40px;
width: 100%;
border: 1px solid #27252d;
border-radius: 5px;
color: #fff;
font-weight: 500;
font-size: 13px;
}

.profiles-sections {
  border:none !important;
  background-color:transparent;
}

.profiles-sections h3{
  margin-bottom: 0;
}

.profiles-sections p{
  margin-bottom: 5px;
  color: #7B7A84;
  font-size: 14px;
}

.show
{
position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}

.pass
{
padding-right: 40px !important;
}

.pass:focus{
  outline: none;
}

.setting_container .container{
  max-width: 100%;
}

.setting_select select{
  padding: 10px !important;
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: #23222C;
  background-image: none;
  position: relative;
  padding-left: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
