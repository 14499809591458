.left-icons{
width: 55px;
background-color: #262626;
height: 55px;
padding-top: 13px;
}
.right-checkouts{
padding-left: 19px;
padding-top: 8px;
}

  .item1 .user-info {
	padding-left:0;
	width: 90%;
	margin-bottom: 5px;
}

/* 2 april styles*/

.item-details-left p{
	margin-bottom:10px;
}

.wid20 {
	width: 20px;
}